import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UserRole } from '@bolt/ui-shared/auth';
import { HttpError } from '@bolt/ui-shared/common';
import { forkJoin, Observable, Observer, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { includes as _includes, isString as _isString } from 'lodash';

import { Configuration } from '../../models/configuration/configuration.model';
import { Privilege } from '../../models/privilege/privilege.model';
import { Toggle } from '../../models/toggle/toggle.model';


@Injectable()
export class AppConfigurationManager {
  protected configurations: Map<string, Configuration>;
  protected readonly configurationsPath: string = '/data/configuration';
  protected roleCapabilities: Map<string, Privilege>;
  protected readonly roleCapabilitiesPath: string = '/data/role-capability';
  protected toggles: Map<string, Toggle>;
  protected readonly togglesPath: string = '/data/toggle';

  constructor(
    protected http: HttpClient
  ) {
    this.initialize();
  }

  /**
   * Returns the current value for the given configuration name.
   *
   * @param name string
   * @throws
   * @returns Configuration
   */
  getConfiguration(name: string): Configuration {
    if (_isString(name)) {
      if (this.configurations.has(name)) {
        return this.configurations.get(name);
      } else {
        throw new HttpError(`There is no configuration for ${name})`);
      }
    } else {
      throw new HttpError(`The type of the key name is not valid (string expected got ${typeof name})`);
    }
  }

  /**
   * Returns the current configurations.
   *
   * @returns Map<string, Configuration>
   */
  getConfigurations(): Map<string, Configuration> {
    return this.configurations;
  }

  /**
   * Returns the current configurations.
   *
   * @returns Configuration[]
   */
  getConfigurationsAsArray(): Configuration[] {
    const newArray: Configuration[] = new Array();

    this.configurations.forEach(
      (configuration: Configuration) => {
        newArray.push(configuration);
      }
    );

    return newArray;
  }

  /**
   * Get the current role capabilities.
   *
   * @returns Map<string, Privilege>
   */
  getRoleCapabilities(): Map<string, Privilege> {
    return this.roleCapabilities;
  }

  /**
   * Get the current toggles.
   *
   * @returns Map<string, Toggle>
   */
  getToggles(): Map<string, Toggle> {
    return this.toggles;
  }

  /**
   * Returns the current value for the given toggle type.
   *
   * @param name string
   * @returns boolean
   */
  getToggleValue(name: string): boolean {
    if (this.toggles.has(name)) {
      return this.toggles.get(name).getValue();
    } else {
      throw new HttpError(`There is no toggle for ${name}`);
    }
  }

  /**
   * Returns the current toggles.
   *
   * @returns Toggle[]
   */
  getTogglesAsArray(): Toggle[] {
    const newArray: Toggle[] = new Array();

    this.toggles.forEach(
      (toggle: Toggle) => {
        newArray.push(toggle);
      }
    );

    return newArray;
  }

  /**
   * Set the configurations with the given data.
   *
   * @param data any[]
   * @returns void
   */
  setConfigurations(data: any[]): void {
    this.configurations = new Map();

    data.forEach(
      (configuration: any) => {
        this.configurations.set(configuration.name, new Configuration(configuration));
      }
    );
  }

  /**
   * Set the role capabilities with the given data.
   *
   * @param data any[]
   * @returns void
   */
  setRoleCapabilities(data: any[]): void {
    this.roleCapabilities = new Map();

    data.forEach(
      (capability: any) => {
        capability.roles = capability.value.map((element: any) => new UserRole({ name: element }));
        this.roleCapabilities.set(capability.name, new Privilege(capability));
      }
    );
  }

  /**
   * Set the toggles map with the given data.
   *
   * @param data any[]
   * @returns void
   */
  setToggles(data: any[]): void {
    this.toggles = new Map();

    data.forEach(
      (toggle: any) => {
        this.toggles.set(toggle.name, new Toggle({ ...toggle, isUiDbToggle: true}));
      }
    );
  }

  /**
   * Loads all the configurations values.
   *
   * @returns Observable<any>
   */
  loadAll(): Observable<any> {
    const obs: Observable<any> = forkJoin([
      this.loadConfigurations(),
      this.loadRoleCapabilities(),
      this.loadToggles()
    ]);

    return obs;
  }

  /**
   * Maps the given error as an HttpError
   *
   * @param error Any
   * @returns HttpError
   */
  protected defaultCatchOn(error: any): HttpError {
    return new HttpError(error.message, error.status, error.statusText);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.configurations = new Map();
    this.roleCapabilities = new Map();
    this.toggles = new Map();
  }

  /**
   * Loads the configurations.
   *
   * @returns Observable<boolean>
   */
  protected loadConfigurations(): Observable<boolean>  {
    const obs: Observable<boolean> = new Observable(
      (observer: Observer<boolean>) => {
        this.http.get(this.configurationsPath).pipe(
          catchError(
            (error: HttpErrorResponse) => {
              return throwError(this.defaultCatchOn(error));
            }
          )
        ).subscribe(
          (response: object[]) => {
            this.setConfigurations(response);
            observer.next(true);
            observer.complete();
          },
          (error: HttpError) => {
            observer.error(error);
            observer.complete();
          }
        );
      }
    );

    return obs;
  }

  /**
   * Loads the role capabilities.
   *
   * @returns Observable<boolean>
   */
  protected loadRoleCapabilities(): Observable<boolean> {
    const obs: Observable<boolean> = new Observable(
      (observer: Observer<boolean>) => {
        this.http.get(this.roleCapabilitiesPath).pipe(
          catchError(
            (error: HttpErrorResponse) => {
              return throwError(this.defaultCatchOn(error));
            }
          )
        ).subscribe(
          (response: object[]) => {
            this.setRoleCapabilities(response);
            observer.next(true);
            observer.complete();
          },
          (error: HttpError) => {
            observer.error(error);
            observer.complete();
          }
        );
      }
    );

    return obs;
  }

  /**
   * Loads the toggles.
   *
   * @returns Observable<boolean>
   */
  protected loadToggles(): Observable<boolean>  {
    const obs: Observable<boolean> = new Observable(
      (observer: Observer<boolean>) => {
        this.http.get(this.togglesPath).pipe(
          catchError(
            (error: HttpErrorResponse) => {
              return throwError(this.defaultCatchOn(error));
            }
          )
        ).subscribe(
          (response: object[]) => {
            this.setToggles(response);
            observer.next(true);
            observer.complete();
          },
          (error: HttpError) => {
            observer.error(error);
            observer.complete();
          }
        );
      }
    );

    return obs;
  }
}
